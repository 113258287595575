body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.app{
  background-color: rgb(38,38,38);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.banner{
  background-image: url('./img/Car+Window+Tint-1920w.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 500px;
}
@media screen and (max-width: 768px) {
  .banner {
    background-size: 150%; 
  }
}

.full-width-carousel {
  width: 100%;
}

.small-full-width-carousel {
  max-width: 100%;
  margin: 0 auto;
}

.full-width-carousel .carousel-inner .carousel-item {
  width: 100%;
}

.full-width-carousel .carousel-inner .carousel-item img {
  width: 100%;
}

.carousel-caption {
  background-color: rgba(0, 0, 0, 0.7); 
  color: white; 
  padding: 20px; 
}

.bannerDiv {
  background: radial-gradient(white, black);
}

@media (max-width: 576px) {
  .banner {
    display: none;
  }
}

.custom-nav-link {
  font-size: 18px;
  font-weight: bold;
  color: red;
}

/* formStyles.css */

/* Center the form */
form {
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}

/* Style form labels */
label {
  color: white;
  display: block;
  margin-bottom: 10px;
}

/* Style form inputs and textarea */
input[type="text"],
input[type="email"],
input[type="phone"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 20px;
}

textarea {
  min-height: 150px;
}

/* Style the submit button */
input[type="submit"] {
  background: blue;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.navToggler{
  outline: none;
}